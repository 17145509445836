<template>
  <div class="App" :class="elmClasses">
    <AppHeader
      :class="{ 'AppHeader--isVisible': isHeaderVisible }"
      v-if="isReady"
    ></AppHeader>

    <AppBody
      :class="{ 'AppBody--isVisible': isHeaderVisible }"
      :sitemap="sitemap"
      v-if="isReady && sitemap"
    ></AppBody>

    <!-- loading spinner -->
    <div
      class="App__loadingState"
      :class="{ 'App__loadingState--isHidden': isReady }"
    >
      <div class="App__loadingStateSpinner2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 180 180"
          vector-effect="non-scaling-stroke"
        >
          <path d="M142.3,130.87A66.38,66.38,0,1,1,130.87,37.7"></path>
        </svg>
      </div>
    </div>

    <ProjectOverlay v-if="isReady"></ProjectOverlay>

    <!--
			Wurde benötigt um den Trigger für das Autoplay der Videos on Mobile sichtbar zu machen
			<div class="inViewportTriggerHelper"></div>
		-->

    <!--
			Erstmal deaktiviert, kommt, wenn überhaupt, als Teil der v2
			<ImageLightbox></ImageLightbox>
		-->

    <div class="gridDebug grid grid--colGap grid--cols-12 hSpace hSpace--app">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <MhDelegateLinks :debugLog="false"></MhDelegateLinks>
    <MhDevInfos
      :showOnHosts="['localhost', 'doity-dev', 'dev.doity.de']"
    ></MhDevInfos>

    <!--
		<MhDevPanel></MhDevPanel>
		--></div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "@/event-bus.js";
import resize from "vue-resize-directive";
import RestHandler from "@/components/RestHandler/RestHandler.js";

import MhRouterViewWrapper from "./components/MhRouterView/v2/MhRouterViewWrapper.vue";
import MhDevInfos from "@/components/MhDevInfos/MhDevInfos.vue";
// import MhDevPanel from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevPanel/v1/MhDevPanel.vue'
import MhDelegateLinks from "@/components/MhDelegateLinks/v3/MhDelegateLinks.vue";
import MhView from "@/components/MhView/MhView.vue";

import AppHeader from "./components/AppHeader.vue";
import AppBody from "./components/AppBody.vue";
import AppFooter from "./components/AppFooter.vue";
import PageContent from "./components/PageContent.vue";
import ProjectOverlay from "./components/ProjectOverlay.vue";
import ImageLightbox from "./components/ImageLightbox.vue";

export default {
  name: "App",
  components: {
    MhRouterViewWrapper,
    // MhDevPanel,
    MhDevInfos,
    MhDelegateLinks,
    MhView,
    AppHeader,
    AppBody,
    AppFooter,
    PageContent,
    ProjectOverlay,
    ImageLightbox,
  },
  directives: {
    resize,
  },
  mixins: [RestHandler],
  data() {
    return {
      posts: [],
      pages: [],
      projects: [],
      directors: [],
      photographers: [],
      acfOptions: {},

      isReady: false,
      isBodyBlured: false,
      isHeaderVisible: false,
      isBodyVisible: false,

      debug: {
        videosMounted: 0,
      },
    };
  },
  computed: {
    app() {
      return this.$root.$children[0];
    },
    elmClasses() {
      let classes = [];

      if (this.isBodyBlured)
        classes.push(this.$options.name + "--isBodyBlured");

      classes.push(this.$route.name);

      return classes;
    },
    isMqBelowDt() {
      const mq = this.$mq;
      const belowDtMqs = ["md", "sm", "xs"];
      return belowDtMqs.includes(mq);
    },
    sitemap() {
      let sitemap = this.isReady ? [] : undefined;

      if (this.isReady) {
        // home/work
        sitemap.push({
          items: [
            {
              path: "/",
              is404: false,
              post: this.getPostBySlug("work"),
            },
          ],
        });

        // directors
        const directorItems = [];
        this.directors.forEach((v, k) => {
          directorItems.push({
            path: this.getLinkWithoutHostname(v.link),
            is404: false,
            post: v,
          });
        });
        sitemap.push({
          items: directorItems,
        });

        // photographers
        const photographerItems = [];
        this.photographers.forEach((v, k) => {
          photographerItems.push({
            path: this.getLinkWithoutHostname(v.link),
            is404: false,
            post: v,
          });
        });
        sitemap.push({
          items: photographerItems,
        });

        // information
        sitemap.push({
          items: [
            {
              path: "/information/",
              is404: false,
              post: this.getPostBySlug("information"),
            },
          ],
        });

        // imprint
        sitemap.push({
          items: [
            {
              path: "/imprint/",
              is404: false,
              post: this.getPostBySlug("imprint"),
            },
          ],
        });

        // dataprotect
        sitemap.push({
          items: [
            {
              path: "/dataprotect/",
              is404: false,
              post: this.getPostBySlug("dataprotect"),
            },
          ],
        });

        // 404
        sitemap.push({
          items: [
            {
              path: "/error-404/",
              is404: true,
              post: this.getPostBySlug("error-404"),
            },
          ],
        });
      }

      return sitemap;
    },
    post() {
      const postSlug =
        this.$route.name === "HomeView" ? "work" : this.$route.params.slug;

      return this.app.getPostBySlug(postSlug);
    },
  },
  methods: {
    getHostname() {
      // for hidding the devInfos on some environments
      return window.location.hostname;
    },
    afterFetchResultFilter(result) {
      /*
				const resultString = JSON.stringify( result )
				const needle = 'http://doity-dev:8080/wp-content/'
				const replace = '/wp-content/'
				const newString = resultString.replaceAll( needle, replace )
				let work = this._.replace( needle, replace, haystack )
				let work = this._.replace( haystack, /doity/g, replace);
				newString.replaceAll( needle, replace )

				return JSON.parse( newString )
				*/
      return result;
    },
    fetchAll(finalCallback, doLog = true) {
      let thingsToLoad = 5;

      const runCallback = () => {
        --thingsToLoad;

        //console.log( this.$options.name, '• fetch() runCallback()', thingsToLoad)

        if (!thingsToLoad && this._.isFunction(finalCallback)) finalCallback();
      };

      // fetch acfOptions
      this.restHandler__fetch({
        action: "GET",
        route: "/wp-json/mh/v1/acfOptions",
        params: {},
        callback: (response) => {
          const collection = response.data.result;

          this.acfOptions = collection;

          if (doLog) {
            console.groupCollapsed(
              this.$options.name,
              "• fetch acfOptions •",
              this._.size(collection)
            );
            console.log("collection:", collection);
            console.groupEnd();
          }

          runCallback();
        },
      });
      // fetch pages
      this.restHandler__fetch({
        action: "GET",
        route: "/wp-json/mh/v1/posts",
        params: {
          postType: "page",
          perPage: -1,
        },
        callback: (response) => {
          let collection = response.data.result;

          collection = this.afterFetchResultFilter(collection);

          //this.pages = collection
          collection.forEach((o) => {
            this.pages.push(o);
          });
          collection.forEach((o) => {
            this.posts.push(o);
          });

          if (doLog) {
            console.groupCollapsed(
              this.$options.name,
              "• fetch pages •",
              this._.size(collection)
            );
            console.log("collection:", collection);
            console.groupEnd();
          }

          runCallback();
        },
      });
      // fetch projects
      this.restHandler__fetch({
        action: "GET",
        route: "/wp-json/mh/v1/posts",
        params: {
          postType: "projects_post",
          perPage: -1,
        },
        callback: (response) => {
          let collection = response.data.result;

          collection = this.afterFetchResultFilter(collection);

          //this.projects = collection
          collection.forEach((o) => {
            this.projects.push(o);
          });
          collection.forEach((o) => {
            this.posts.push(o);
          });

          if (doLog) {
            console.groupCollapsed(
              this.$options.name,
              "• fetch projects •",
              this._.size(collection)
            );
            console.log("collection:", collection);
            console.groupEnd();
          }

          runCallback();
        },
      });
      // fetch directors
      this.restHandler__fetch({
        action: "GET",
        route: "/wp-json/mh/v1/posts",
        params: {
          postType: "directors_post",
          perPage: -1,
        },
        callback: (response) => {
          let collection = response.data.result;

          collection = this.afterFetchResultFilter(collection);

          //this.directors = collection
          collection.forEach((o) => {
            this.directors.push(o);
          });
          collection.forEach((o) => {
            this.posts.push(o);
          });

          if (doLog) {
            console.groupCollapsed(
              this.$options.name,
              "• fetch directors •",
              this._.size(collection)
            );
            console.log("collection:", collection);
            console.groupEnd();
          }

          runCallback();
        },
      });
      // fetch photographers
      this.restHandler__fetch({
        action: "GET",
        route: "/wp-json/mh/v1/posts",
        params: {
          postType: "photographers_post",
          perPage: -1,
        },
        callback: (response) => {
          let collection = response.data.result;

          collection = this.afterFetchResultFilter(collection);

          //this.photographers = collection
          collection.forEach((o) => {
            this.photographers.push(o);
          });
          collection.forEach((o) => {
            this.posts.push(o);
          });

          if (doLog) {
            console.groupCollapsed(
              this.$options.name,
              "• fetch photographers •",
              collection.length
            );
            console.log("collection:", collection);
            console.groupEnd();
          }

          runCallback();
        },
      });
    },

    isLandscapeMedia(imageObj) {
      const isLandscape = imageObj.width > imageObj.height;
      //console.log('imageObj:', imageObj, isLandscape )
      return isLandscape;
    },
    setCssVars() {
      const root = document.documentElement;
      root.style.overflow = "auto";
      root.style.height = "101vh";
      const scrollbarWidth = window.innerWidth - root.offsetWidth + "px";
      root.style.height = "";
      root.style.overflow = "";

      //root.style.setProperty('--scrollbarWidth', scrollbarWidth)

      // 2021-07-13  scrollbars should be complettly hiden
      root.style.setProperty("--scrollbarWidth", "0px");
    },
    getPostBySlug(slug) {
      return this.posts.find((o) => {
        return o.slug === slug;
      });
    },
    getPostById(id) {
      return this.posts.find((o) => {
        return o.id === id;
      });
    },
    getAspectRatioByMedia(mediaObj) {
      const width = this._.get(mediaObj, "width", 16);
      const height = this._.get(mediaObj, "height", 9);

      return width + ":" + height;
    },
    getLinkWithoutHostname(url) {
      let theReturn = "";

      try {
        let _url = new URL(url);
        theReturn = _url.pathname;
      } catch (e) {
        //
      }

      return theReturn;
    },

    getCellProjectSlug(cellObj) {
      const projectId = cellObj.projectId;
      const projectPost = this.app.getPostById(projectId);
      const projectPostSlug = this._.get(projectPost, "slug");

      return projectPostSlug;
    },
    getCellImage(cellObj, cellIndex) {
      const boxType = cellObj.acf_fc_layout;
      let boxImage = undefined;

      if (boxType == "box--project") {
        const projectId = cellObj.projectId;
        const projectPost = this.app.getPostById(projectId);
        boxImage = this._.get(projectPost, "acf.teaserImage");
      }
      if (boxType == "box--photo") {
        boxImage = this._.get(cellObj, "image");
      }

      // no image on first box on home >
      // make autoplay hero-video visible
      /*
				if( this.$route.name === 'HomeView' && cellIndex === 0 ){
					boxImage = undefined
				}
				*/

      return boxImage;
    },
    getCellClip(cellObj, debugPost = false, doLog = true) {
      const projectId = cellObj.projectId;
      const projectPost = this.app.getPostById(projectId);
      const projectClip = this._.get(projectPost, "acf.clip");

      //console.log('projectPost:', projectPost)

      return projectClip;
    },
    getCellText(cellObj, index, post) {
      return cellObj.text;
    },
    getCellTextVerticalAlign(cellObj) {
      return cellObj.textVerticalAlign;
    },
    getCellTitle(cellObj, index, post) {
      const projectId = cellObj.projectId;
      const projectPost = this.app.getPostById(projectId);
      const internalOrExternal = this._.get(
        projectPost,
        "acf.artistInternalOrExternal"
      );
      const isInternalArtist = internalOrExternal !== "external" ? true : false;
      const postTitle = this._.get(projectPost, "title.rendered");
      const acfTitle = this._.get(projectPost, "acf.title");
      const projectArtistId = this._.get(projectPost, "acf.artist", false);
      const projectArtistPost = this.getPostById(projectArtistId);
      const projectArtistTitle = this._.get(
        projectArtistPost,
        "title.rendered"
      );
      const projectArtistLink = this.getLinkWithoutHostname(
        this._.get(projectArtistPost, "link")
      );
      let theReturn = acfTitle ? acfTitle : postTitle;

      //console.log('getCellTitle:', post, projectArtistPost, projectArtistLink, projectArtistTitle, internalOrExternal, isInternalArtist)

      // auf der startseite soll der artist des projects mit ausgegeben werden
      // interne artists (mit internen link)
      if (
        this._.get(post, "slug") === "work" &&
        projectArtistTitle &&
        isInternalArtist
      ) {
        theReturn =
          theReturn +
          'by <strong><a href="' +
          projectArtistLink +
          '">' +
          projectArtistTitle +
          "</a></strong>";
      }
      // externe artists (ohne internen link )
      if (!isInternalArtist && this._.get(projectPost, "acf.externalArtist")) {
        theReturn =
          theReturn +
          "by <strong>" +
          this._.get(projectPost, "acf.externalArtist") +
          "</strong>";
      }

      return theReturn;
    },
    getCellAspectRatio(cellObj) {
      const boxType = cellObj.acf_fc_layout;
      const aspectRatioBy = cellObj.aspectRatioBy;
      let aspectRatio = "auto";

      const projectId = cellObj.projectId;
      const projectPost = this.app.getPostById(projectId);
      const projectTeaserImage = this._.get(projectPost, "acf.teaserImage");

      if (aspectRatioBy == "teaserImage" && boxType == "box--project") {
        aspectRatio = this.app.getAspectRatioByMedia(projectTeaserImage);
      }
      if (boxType == "box--photo") {
        aspectRatio = this.app.getAspectRatioByMedia(
          this._.get(cellObj, "image")
        );
        //aspectRatio = '1:1'
      }
      if (aspectRatioBy == "quadrat") {
        aspectRatio = "1:1";

        if (this.isMqBelowDt && projectTeaserImage) {
          aspectRatio = "auto";
          aspectRatio = this.app.getAspectRatioByMedia(projectTeaserImage);
        }
      }

      return aspectRatio;
    },
    getCellContentAspectRatio(cellObj, doLog = false) {
      const isProject = cellObj.acf_fc_layout === "box--project";
      let aspectRatio = "auto";

      //console.log('cellObj', cellObj)

      // groupCollapsed group
      if (doLog) {
        console.groupCollapsed(
          this.$options.name,
          "• getCellContentAspectRatio( cellObj )"
        );
        console.log("cellObj", cellObj);
        console.log("isProject", isProject);
      }

      // contentAspectRatio by image
      if (isProject) {
        const projectId = cellObj.projectId;
        const projectPost = this.getPostById(projectId);
        const projectTeaserImage = this._.get(projectPost, "acf.teaserImage");
        const imageAspectRatio = this.getAspectRatioByMedia(projectTeaserImage);

        if (doLog) {
          console.log("projectPost", projectPost);
          console.log("projectTeaserImage", projectTeaserImage);
          console.log("imageAspectRatio", imageAspectRatio);
        }

        aspectRatio = imageAspectRatio;
      }

      if (doLog) {
        console.groupEnd();
      }

      return aspectRatio;
    },
    getCellAutoplayClip(cellObj, cellIndex) {
      const isValidView = this.$route.name === "HomeView" ? true : false;
      const isFirstCell = cellIndex === 0 ? true : false;
      const isValidMq = !this.isMqBelowDt;
      //const isValidMq = false
      //const hasOpenOverlay = this.$route.query.project ? true : false

      return isValidView && isFirstCell && isValidMq ? true : false;
    },
  },
  mounted() {
    // get scrollbar width and set as css var
    this.setCssVars();

    // set isReady which:
    // - renders the body
    // - fades out the spinner
    // - fades in the header and the body
    this.fetchAll(() => {
      this.isReady = true;

      setTimeout(() => {
        this.$nextTick(() => {
          this.isHeaderVisible = true;
          this.isBodyVisible = true;
        });
      }, 75);
    });

    EventBus.$on("ProjectOverlay is open", () => {
      this.isBodyBlured = true;
    });
    EventBus.$on("ProjectOverlay is closed", () => {
      this.isBodyBlured = false;
    });
    EventBus.$on("AppHeader Submenu is open", () => {
      this.isBodyBlured = true;
    });
    EventBus.$on("AppHeader Submenu is closed", () => {
      this.isBodyBlured = false;
    });

    EventBus.$on("ProjectOverlay is open", () => {
      const htmlElm = document.querySelector("html");
      const bodyElm = document.querySelector("body");

      //htmlElm.style.overflow = 'hidden'
    });
    EventBus.$on("ProjectOverlay is closed", () => {
      const htmlElm = document.querySelector("html");
      const bodyElm = document.querySelector("body");

      //htmlElm.style.overflow = ''
    });

    // for debug: count mounted videos to handle
    // chrome max 75 video-tags limit
    // >> habe ich später anders gelöst
    EventBus.$on("MhVideo mounted", () => {
      ++this.debug.videosMounted;
      //console.log('videosMounted:', this.debug.videosMounted)
    });

    // evenutell benötige ich später noch diesen fps counter
    // um den backdrop-filter o.ä. aus performance-gründen
    // abschalten zu können
    const detectFps = () => {
      const times = [];
      let fps;

      function refreshLoop() {
        window.requestAnimationFrame(() => {
          const now = performance.now();
          while (times.length > 0 && times[0] <= now - 1000) {
            times.shift();
          }
          times.push(now);
          fps = times.length;
          refreshLoop();
        });
      }
      refreshLoop();

      // output to console once per second
      setInterval(() => {
        console.log(fps);
      }, 1000);
    };
    //detectFps()
  },
};
</script>

<style lang="less">
@import (reference) "less/vars.less";
@import (reference) "less/mixins.less";
@import "less/atoms.less";

html {
  overflow: hidden;
}
body {
  .font;
  .font--sans;
  .font--sizeDefault;

  background-color: @swatches[bgBlack];
  overflow: hidden;
  //opacity: 0.5;
}
strong,
b {
  .font--bold;
}
button {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}
a {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}
pre {
  @color: black;
  @backgroundColor: lighten(black, 50);

  position: relative;
  padding: 0.5em;
  width: 100%;
  min-height: 2em;

  margin-bottom: 0.5em;
  &:first-of-type {
    margin-top: 1em;
  }
  &:last-of-type {
    margin-bottom: 1em;
  }

  //color: fade(@color, 65);
  tab-size: 4;
  white-space: pre;

  outline: 1px solid fade(@color, 25);
  background-color: @backgroundColor;
  overflow: auto;
  //display: none;

  // label
  &[name]:after {
    position: absolute;
    top: 0;
    right: 0;
    background-color: fade(@color, 15);
    font-size: 13px;
    line-height: 1em;
    font-family: sans-serif;
    color: fade(@color, 75);
    padding: 0.25em 0.5em;
    content: attr(name);
    font-family: inherit;
  }

  &:first-child {
    margin-top: 0em;
  }

  &[maxHeight] {
    max-height: 250px;
  }
}
hr {
  border: none;
  border-top: 1px solid;
  margin: 0.5em 0;
  opacity: 0.25;
}
ul,
ol {
  margin-left: 1rem;
}

.hasOverflow {
  overflow: hidden;
  overflow-y: scroll;

  &--hideScrollbars {
    &::-webkit-scrollbar {
      display: none;
    } /* Chrome, Safari and Opera */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.gridDebug {
  [showborders5] & {
    visibility: visible;
  }
  //[showborders5] & { margin: 0 1em; }

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--scrollbarWidth));
  min-height: 100vh;

  z-index: 100;
  pointer-events: none;
  outline: 1px solid cyan;
  visibility: hidden;

  & > * {
    background-color: fade(cyan, 50);
    outline: 1px solid cyan;
  }
}
.gridRowGap {
  height: 1rem;
}

.hSpace--app {
  padding-left: @app[dtPaddingLeft];
  padding-right: @app[dtPaddingRight];
}
.vSpace--app {
  padding-bottom: 3px;

  @media @mediaQueries[dt] {
    padding-top: @app[dtHeaderHeight];
  }
  @media @mediaQueries[md] {
    padding-top: @app[mdHeaderHeight];
  }
}
.richText {
  a {
    transition: all @transitions[linkTransitionDuration] ease;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: @swatches[textRed];
    }
  }
  .headline {
    font-size: 28px;
    line-height: 1.2em;
  }
  .headline--big {
    .font--sizeBig;
  }
  .columns {
    display: flex;
    padding-top: 1em;
  }
  .column {
    width: 50%;
    flex-shrink: 0;

    &:first-child {
      padding-right: calc(@app[gridGap] / 2);
    }
    &:last-child {
      padding-left: calc(@app[gridGap] / 2);
    }
  }
  p + p {
    margin-top: 15px;
  }
}
.maxWidthText {
  .richText {
    width: 66%;
  }
}
.noPointerEvents {
  pointer-events: none;
}
.blurBackground {
  //display: none !important;

  position: absolute;
  top: 0;
  left: 0px;
  right: 0px; // bottom: 0;

  // firefox
  background-color: fade(black, 50);

  // detect feature suppoert and use the cool stuff
  // chrome + safari + edge
  // but firefox not :(
  @supports (backdrop-filter: blur(75px)) or
    (-webkit-backdrop-filter: blur(75px)) {
    background-color: transparent;
    -webkit-backdrop-filter: blur(65px);
    backdrop-filter: blur(65px);
    /*
			*/
  }
}
.placeholderCell {
  background-color: fade(black, 50);
}
.customScrollbars {
  /* width */
  &::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    transition: background 0.35s ease;
    background: fade(white, 50);
    //border: 4px solid fade(black, 5);
    border: 4px solid black;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: fade(white, 95);
  }

  // hide scrollbars an AppBody sliding to view
  .AppBody--isAnimating &::-webkit-scrollbar-thumb {
    background: black;
  }
}
.inViewportTriggerHelper {
  position: fixed;
  top: 25vh;
  right: 0;
  left: 0;
  height: 3px;
  z-index: 1000;
  background-color: fade(red, 35);

  @media @mediaQueries[dt] {
    display: none;
  }
  @media @mediaQueries[md] {
    display: block;
  }
}

.App {
  position: absolute;
  top: 0;
  width: 100vw;
  overflow: hidden;

  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;

  color: @swatches[textWhite];
}
.App {
  // loading transitions
  &Header,
  &Body {
    transition: all 0.2s ease;
    opacity: 0;
  }

  &Header--isVisible,
  &Body--isVisible {
    opacity: 1;
  }
}
.App__loadingState {
  // loading spinner on start
  --spinnerHeight: 3rem;
  --spinnerWidth: 3rem;
  --spinnerTransitionDuration: 350ms;
  --spinnerColor: fade(white, 50);
  --spinnerStrokeWidth: 5px;
  --spinnerOpacity: 0.35;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity var(--spinnerTransitionDuration) ease-out;
  transition-delay: 0.15s;
  background-color: black;
  opacity: 1;

  &--isHidden {
    pointer-events: none;
    opacity: 0;
  }

  &Spinner {
    // spinner position and appearance
    position: absolute;
    top: 50vh;
    left: 50vw;
    height: var(--spinnerHeight);
    width: var(--spinnerWidth);
    transform: translateX(-50%) translateY(-50%) scale(1);
    pointer-events: none;
    transition: all var(--spinnerTransitionDuration) ease-out;

    circle {
      stroke: var(--spinnerColor);
      stroke-opacity: 0.5;
      stroke-opacity: var(--spinnerOpacity);
      stroke-width: var(--spinnerStrokeWidth);
    }
    path {
      stroke: var(--spinnerColor);
      stroke-width: var(--spinnerStrokeWidth);
    }
  }
  &Spinner2 {
    --spinnerHeight: 180px;
    --spinnerWidth: 180px;

    position: absolute;
    top: 50vh;
    left: 50vw;
    height: var(--spinnerHeight);
    width: var(--spinnerWidth);
    transform: translateX(-50%) translateY(-50%) scale(1);
    pointer-events: none;
    transition: all var(--spinnerTransitionDuration) ease-out;
    //outline: 1px solid red;

    svg {
      animation: rotation 1s infinite linear;
    }
    path {
      fill: none;
      stroke: #fff;
      //stroke-width: 1px;
      stroke-miterlimit: 10;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}

// global debug styling
.GridCell {
  [showborders6] & {
    background-color: fade(red, 25);
  }
}
.MediaBox {
  [showborders6] & {
    border: 1px solid red;
  }
}

@media @mediaQueries[xs] {
}
@media @mediaQueries[sm] {
}
@media @mediaQueries[md] {
}
@media @mediaQueries[dt] {
}
@media @mediaQueries[lg] {
}
@media @mediaQueries[xl] {
}
</style>
